@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+Display:wght@300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+Display:wght@300;400&family=Poppins:wght@100&display=swap');

details > summary {
    list-style: none;
}

details > summary::-webkit-details-marker {
    display: none;
}
